import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/user-style.css"

const carouselInterval = 3000

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <main className="flex-shrink-0">
      <header className="bg-dark py-5">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="display-5 fw-bolder text-white mb-2">
                  モダン組み込みソフト開発
                </h1>
                <p className="lead fw-normal text-white-50 mb-4">
                  世界に通用する日本のものづくりへ。
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
              <div
                id="carouselExampleInterval"
                className="carousel slide"
                data-bs-interval={carouselInterval}
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <a href="#scroll-target-embedded-wizard">
                      <img
                        src="/images/logo-embedded-wizard.svg"
                        className="d-block w-100"
                        alt="Embedded Wizard"
                      />
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="#scroll-target-safertos">
                      <img
                        src="/images/logo-safertos.svg"
                        className="d-block w-100 bg-light"
                        alt="SafeRTOS"
                      />
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="#scroll-target-openrtos">
                      <img
                        src="/images/logo-openrtos.svg"
                        className="d-block w-100 bg-light"
                        alt="SafeRTOS"
                      />
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="#scroll-target-tracealyzer">
                      <img
                        src="/images/logo-tracealyzer.svg"
                        className="d-block w-100 bg-light"
                        alt="TraceAlyzer"
                      />
                    </a>
                  </div>
                  <div className="carousel-item">
                    <a href="#scroll-target-cyclonetcp">
                      <img
                        src="/images/logo-cyclone-tcp.svg"
                        className="d-block w-100 bg-light"
                        alt="CycloneTCP"
                      />
                    </a>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Embedded Wizard */}
      <section className="bg-light" id="scroll-target-embedded-wizard">
        <div className="container px-5 py-4">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <a href="/product_embedded_wizard/">
                <img
                  className="img-fluid rounded mb-4 mb-lg-0"
                  src="/images/logo-embedded-wizard.svg"
                  alt="..."
                />
              </a>
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">
                Embedded Wizard
                <br />
                魔法のようなUI開発
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                GUI開発に専念して20年!
                <br />
                Tara Systems社が開発した組み込み専用GUI開発ツール。
                <br />
                UI開発の新パラダイム。
                <br />
                高品質で迅速な UI開発をサポート。
              </p>
              <div className="my-4">
                <a
                  href="/product_embedded_wizard/"
                  className="btn btn-outline-primary btn-lg"
                  aria-disabled="true"
                >
                  詳細
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* SafeRTOS */}
      <section className="" id="scroll-target-safertos">
        <div className="container px-5 py-4">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="/images/logo-safertos.svg"
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">
                SAFERTOS
                <br />
                安全規格の事前認証済み
                <br />
                リアルタイムOS
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                セーフティーシステム会社
                <br />
                WITTENSTEIN High Integrity Systems社が開発したリアルタイムOS。
                <br />
                IEC 61508-3 SIL 3、ISO 26262 ASIL D認証取得。
                <br />
                FreeRTOSからポーティングが容易。
              </p>
              <div className="my-4">
                <a
                  href="/product_safertos/"
                  className="btn btn-outline-primary btn-lg"
                  aria-disabled="true"
                >
                  詳細
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* OPENRTOS */}
      <section className="bg-light" id="scroll-target-openrtos">
        <div className="container px-5 py-4">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src="/images/logo-openrtos.svg"
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">
                OPENRTOS
                <br />
                FreeRTOSの商用ライセンスバージョン
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                ソフトの保証付き。
                <br />
                しっかりとしたサポート。
                <br />
                製品への著作権・許諾表示義務が一切不要。
                <br />
              </p>
              {/* <div className="my-4">
                <a
                  href="/product_openrtos/"
                  className="btn btn-outline-primary btn-lg"
                  aria-disabled="true"
                >
                  詳細
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* TraceAlyzer */}
      <section className="" id="scroll-target-tracealyzer">
        <div className="container px-5 py-4">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="/images/logo-tracealyzer.svg"
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">
                TraceAlyzer
                <br />
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                リアルタイムOS専門企業、Percepioが開発。
                <br />
                リアルタイムOSの可視化ツール。
                <br />
                OSの内部をすべてお見せします！
              </p>
              {/* <div className="my-4">
                <a
                  href="/product_tracealyzer/"
                  className="btn btn-outline-primary btn-lg"
                  aria-disabled="true"
                >
                  詳細
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* CycloneTCP */}
      <section className="bg-light" id="scroll-target-cyclonetcp">
        <div className="container px-5 py-4">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="/images/logo-cyclone-tcp.svg"
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">
                Cyclone TCP
                <br />
                組み込み専用
                <br />
                IPv4/IPv6デュアルスタック
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                組み込みネットワーク専門企業
                <br />
                Oryx Embeddedが自身を持ってお届けするTCP/IPスタック。
                <br />
                軽量かつ多様なプロトコルに対応。
                <br />
                最新プロトコルHTTP/2、MQTT、WebSocketに対応。
                <br />
                TLS1.3時代のセキュアなIoT開発の必須ソフト！
              </p>
              <div className="my-4">
                <a
                  href="/product_cyclonetcp/"
                  className="btn btn-outline-primary btn-lg"
                  aria-disabled="true"
                >
                  詳細
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial section*/}
      <section>
        <div className="py-5">
          <div className="container px-5 py-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="text-center">
                  <div className="fs-4 fst-italic">
                    “Dioiz has been in the embedded engineering industry more
                    than a decade.
                    <br />
                    With rich low and high level technical support experience,
                    <br />
                    Dioiz can advise success path to achieve your embedded
                    project."
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-center">
                    <div className="fw-bold">
                      <span className="fw-bold text-primary mx-1"></span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default IndexPage
